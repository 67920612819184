import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { getBuilderId } from '../../../redux/action/action';
import { Form, Field, FormikProvider, useFormik } from 'formik';
import { Button } from '../../Button/Button';
import Footer from '../../Footer/Footer';
import Swal from 'sweetalert2';
import axios from 'axios';
import { backendRoutes, frontendRoutes } from '../../../Constants/constants';
import { Spinner } from 'react-bootstrap';

const Offer = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams<{ builder_name?: string }>();

  const builderSettings = useSelector((state: any) => state.builder.settings);
  const userInfo = useSelector((state: any) => state.user.userInfo)
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState('');

  const user_token = localStorage.getItem('static_token');
  const propertyId = localStorage.getItem('pid');


  useEffect(() => {
    dispatch(getBuilderId());
  }, [dispatch]);

  const previousPath = () => {
		history.goBack();
	}

  const successModal = () => {
    Swal.fire({
      title: "Thank You!",
      text: "Your offer has been successfully created.",
      icon: "success",
      titleText: "",
      confirmButtonColor: builderSettings.primary_color,
      confirmButtonText: "Show More Properties",
    }).then((result) => {
      if (result.isConfirmed) {
        history.push(frontendRoutes.chooseCommunity(params.builder_name).path);
      }
    });

  }

  const formik = useFormik({
    initialValues: {
      prequalified: 'false',
      floor_plan: '',
      move_in: '',
      comments: '',
      preferred_contact: 'cell'
    },
    onSubmit: async (values, { setSubmitting }) => {
      const formData = {
        ...values,
        property_id: propertyId,
        customer_id: userInfo?.customer_id,
        prequalified: values.prequalified === 'true',
      };
      setIsLoading(true);
      axios({
        baseURL: backendRoutes.MOBILE_BASE_URL,
        url: `offer`,
        method: 'POST',
        headers: {
          'x-auth-token': user_token,
        },
        data: formData,
      })
        .then(res => {
          setIsLoading(false);
          if (res.status === 201) {
            setIsError('')
            successModal();
          }
        })
        .catch(err => {
          setIsLoading(false);
          const msg = err.response.data;
          setIsError(msg)
          console.log(msg);
        });
    },
  });

  return (
    <section className="main-section community-screen d-flex justify-content-center">
      <div className="box">
        <div className="main-title">
					<h1>Offer</h1>
				</div>
				<span onClick={previousPath} className="link-back icn icn-back-arrow"></span>
        <FormikProvider value={formik}>
          <Form className="form-content" style={{ padding: '20px' }}>
            <div className="form-group">
            <label htmlFor="prequalified" className="custom-label">Are you prequalified?</label>
              <Field as="select" name="prequalified" className="form-control">
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Field>
            </div>
            <div className="form-group">
              <Field 
                as="textarea" 
                name="floor_plan" 
                className="form-control" 
                placeholder="Which floor plan do you like the best..." 
              />
            </div>
            <div className="form-group">
              <Field 
                as="textarea" 
                name="move_in" 
                className="form-control" 
                placeholder="What is your approx. move-in date..." 
              />
            </div>
            <div className="form-group">
              <Field 
                as="textarea" 
                name="comments" 
                className="form-control" 
                placeholder="Extra comments..." 
              />
            </div>
            <div className="form-group">
            <label htmlFor="preferred_contact" className="custom-label">Preferred contact option</label>
              <Field as="select" name="preferred_contact" className="form-control">
                <option value="cell">Cell</option>
                <option value="email">Email</option>
              </Field>
            </div>
            <div className="mt-4" style={{paddingLeft: "20px"}}>     
              {isLoading ? 
                <Button className="button w-100 d-flex justify-content-center align-items-center spinner-btn" type="button">
                  <Spinner animation="border" />
                </Button>
                :
                <Button 
                style={{ background: builderSettings?.primary_color}}
                className="button w-100 d-flex justify-content-center align-items-center spinner-btn" 
                type="submit">
                  Start Offer
                </Button>
                
                }
            </div>
          </Form>
          {isError && <p className='mt-3 text-danger'>{isError}</p>}
        </FormikProvider>
        <Footer />
      </div>
    </section>
  );
};

export default Offer;