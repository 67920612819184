import {
  ADD_STATIC_TOKEN_FAIL,
  ADD_STATIC_TOKEN_REQUEST,
  ADD_STATIC_TOKEN_SUCCESS,
  USER_DETAIL_FAIL,
  USER_DETAIL_REQUEST,
  USER_DETAIL_SUCCESS,
  GET_USER_INFO_REQUEST,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_FAIL
} from "../types/actionTypes";

// export interface UserDetailsState {
//   email?: string;
// }
// export interface UserDetailsReducerState {
//   user?: UserDetailsState;
//   loading?: Boolean;
//   error?: any;
// }

// type initProps = {
//   user: userProps;
// };

// type userProps = {
//   email?: string;
// };

// const initState = {
//   user: {},
//   loading: true,
// };

// export interface ActionType {
//   type: string;
//   payload: any;
// }

export default function UserDetailsReducer(
  state = {},
  action: any
) {
  switch (action.type) {
    case ADD_STATIC_TOKEN_REQUEST:
      return { ...state, loading: true };
    case ADD_STATIC_TOKEN_SUCCESS:
      return { loading: false, token: action.payload };
    case ADD_STATIC_TOKEN_FAIL:
      return { loading: false, error: action.payload };
    case USER_DETAIL_REQUEST:
      return { ...state, loading: true };
    case USER_DETAIL_SUCCESS:
      return { loading: false, user: action.payload };
    case USER_DETAIL_FAIL:
      return { loading: false, error: action.payload };
    case GET_USER_INFO_REQUEST:
      return { ...state, loading: true };
    case GET_USER_INFO_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case GET_USER_INFO_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}
